import React from 'react';
import { Mixpanel } from '@/utils';
import { SingleGutTestPricing } from '@/components/pricing/SingleGutTest';
import { FamilyBundlePlanPricing } from '@/components/pricing/FamilyBundlePlan';

export default function Pricing() {
  React.useEffect(() => Mixpanel.track(`Pricing Plan`), []);

  return (
    <section id="mem" className="bg-white relative pb-[32px] slg:pb-[120px]">
      <div className="bg-[#F8E6E2] py-16 md:pt-[132px] md:pb-[161px] relative">
        <div className="sm:max-w-[693px] mx-auto px-8 md:px-0 text-center">
          <legend className="font-bold text-center mx-auto md:text-[60px] text-2xl text-[#484352] md:leading-[60px]">
            Ready to start <br />
            <span className="text-[#54BA6E] font-bold md:text-[60px] text-2xl">
              Choose a plan
            </span>
          </legend>
        </div>
        <img
          src="/images/pricing-offer-hand.png"
          alt="Vitract - Pricing"
          className="absolute left-0 top-[-50px] hidden sm:block"
        />
        <img
          src="/images/pricing-offer-hand-right.png"
          alt="Vitract - Pricing"
          className="absolute right-0 top-[-50px] hidden sm:block"
        />
      </div>

      <div className="justify-center mt-[60px] mb-[60px]">
        <h2 className="font-bold text-[32px] text-center text-[#484352]">
          Plans & Pricing
        </h2>
      </div>

      <div className="slg:mt-[120px] slg:max-w-[1180px] slg:max-h-[694px] mx-auto md:mx-[162px] slg:mx-auto flex slg:flex-row flex-col gap-y-[20px] slg:gap-x-[20px] slg:gap-y-0 px-4 slg:px-0">
        <SingleGutTestPricing />
        <FamilyBundlePlanPricing />
      </div>
    </section>
  );
}
