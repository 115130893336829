import React from 'react';
import { getCurrencyByDomain } from '@/utils/domain';
import Link from '../Link';
import './index.css';

// const BENEFITS = [
//   `Get 3 gut tests at a time and save 50USD / 90CAD. This is ideal for a family, or for time-lapse testing`,
// ];

// type Props = { navigateToProduct: any };

export function FamilyBundlePlanPricing() {
  function formatPrice(price: number, currency: string): string {
    let formattedPrice: string;

    if (currency === `CAD`) {
      formattedPrice = `${Math.round(price * 1.429)} CAD`; // Convert to CAD and Round to the nearest whole number
    } else {
      formattedPrice = `${Math.round(price)} USD`; // Default to USD Nd Round to the nearest whole number
    }
    return formattedPrice;
  }

  const domain = typeof window !== `undefined` ? window.location.hostname : ``;
  const selectedCurrency = getCurrencyByDomain(domain);

  const stripeLink = process.env.GATSBY_FAMILY_BUNDLE_STRIPE_URL;
  // const stripeLink = `https://buy.stripe.com/fZecQQaGN6tr3QY8x0`;

  // const stripeLinkUSD = `https://buy.stripe.com/9AQ1880294ljfzG5kM`;
  // const stripeLinkCAD = `https://buy.stripe.com/4gwaII3elaJH5Z65kK`;

  const BENEFITS = `Get 3 gut tests at a time and save ${
    domain === `vitract.ca` || domain === `vitract-ca.netlify.app`
      ? `$30`
      : `$30`
  }. This is ideal for a family, or for time-lapse testing`;

  return (
    <div className="w-full bg-[#CAECCF] md:p-[24px] p-8 h-full md:mt-[24px] slg:mt-0 md:pt-[48px] hover:shadow-[0px_-4px_8px_rgba(217,_185,_204,_0.2),_0px_4px_8px_rgba(217,_185,_204,_0.2)] hover:scale-[1.015] transition-all duration-[0.1s] ease-in-out group">
      <div className="h-[4px] rounded-[8px] bg-[#19233F]" />
      <h3 className="text-[24px] font-[500] mt-[20px] text-center">
        Family Bundle (3 tests)
        <div>
          {/* <span className="text-[30px] font-[700]">$50</span> */}
          <span className="text-[30px] font-[700]">
            {formatPrice(420, selectedCurrency)}
          </span>
        </div>
      </h3>
      <div className="flex flex-col gap-y-[24px] mt-[36px]">
        {/* {BENEFITS.map((b: string) => (
          <div key={b} className="flex items-start gap-x-[24px] h-fit">
            <img src="/check.svg" alt="" />
            <h4 className="text-[#19233F] text-[20px] -mt-2 leading-[30px] 3">
              {b}
            </h4>
          </div>
        ))} */}

        <div className="flex items-start gap-x-[24px] h-fit">
          <img src="/check.svg" alt="" />
          <h4 className="text-[#19233F] text-[20px] -mt-2 leading-[30px] 3">
            {BENEFITS}
          </h4>
        </div>
      </div>

      {domain.includes(`.ca`) ||
      domain.includes(`vitract-ca.netlify.app/pricing/`) ? (
        <Link href={stripeLink}>
          <button
            type="button"
            // onClick={() => navigateToProduct(`nutrition`, `Nutrition Coaching`)}
            className="mt-[40px] w-full border-[#19233F] border-solid border-[1px] rounded text-[#19233F] hover:bg-[#19233F] group-hover:bg-[#19233F] group-hover:text-[#FFFFFF] hover:text-[#FFFFFF] py-[18px] text-[20px] font-medium"
          >
            Choose Test
          </button>
        </Link>
      ) : (
        <Link href={stripeLink}>
          <button
            type="button"
            // onClick={() => navigateToProduct(`nutrition`, `Nutrition Coaching`)}
            className="mt-[40px] w-full border-[#19233F] border-solid border-[1px] rounded text-[#19233F] hover:bg-[#19233F] group-hover:bg-[#19233F] group-hover:text-[#FFFFFF] hover:text-[#FFFFFF] py-[18px] text-[20px] font-medium"
          >
            Choose Test
          </button>
        </Link>
      )}
    </div>
  );
}
