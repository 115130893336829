export function getCurrencyByDomain(domain: string): string {
  if (domain === `vitract.com`) {
    return `USD`;
  }
  if (domain === `vitract.ca` || domain === `vitract-ca.netlify.app`) {
    return `CAD`;
  }
  return `USD`; // Default Currency
}

// export function formatPrice(price: number, currency: string): string {
//   if (currency === `CAD`) {
//     const formattedPriceInCAD = Math.round(price * 1.3);
//     return `$${formattedPriceInCAD}`; // Round to the nearest whole number
//   }
//   const formattedPriceInUSD = Math.round(price); // Round to the nearest whole number
//   return `$${formattedPriceInUSD}`; // Default to USD
// }
