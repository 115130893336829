import React, { useState } from 'react';
import Layout from '@/layouts/main';
import { Mixpanel } from '@/utils';
import Pricing from '@/views/pricing';
import PricingModalComponent from '@/components/PricingModal';

export default function PricingPage() {
  React.useEffect(() => Mixpanel.track(`View Pricing Page`), []);
  const [togglePricingModal, setTogglePricingModal] = useState(false);
  const [url] = useState<string>(undefined);

  return (
    <Layout
      title="Pricing - Transforming your health through your gut"
      description="We aim to reduce over-dependence on pills by using good food to better the health
outcomes of people living with anxiety and depression."
      active="pricing"
    >
      <PricingModalComponent
        open={togglePricingModal}
        setOpen={setTogglePricingModal}
        callback={() => {
          window.location.href = url;
        }}
      />
      <Pricing />
    </Layout>
  );
}
